import './App.css';
import FacebookLog from './components/facebookLog';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Router>

      <FacebookLog />
    </Router>
  );
}

export default App;
